import React from 'react'
import styled, { css } from 'styled-components'
import { devices } from '../../../styleConstants'
import { LeftArrow, RightArrow } from 'styled-icons/boxicons-solid/'

const TRANSITION_DURATION = 3600

enum SlideState {
  IN = 'in',
  OUT = 'out',
  STANDBY = 'standby',
  OUT_LEFT = 'out-left',
  STANDBY_LEFT = 'standby-left',
}

export interface Props {
  images: string[]
  isShowSwitchButton?: boolean
  isCover?: boolean
  duration?: number
}

interface EachSlideProps {
  state: SlideState
}

interface IndicatorProps {
  isActive: boolean
}

export default function Slider({ images, isShowSwitchButton, isCover, duration = 8000 }: Props) {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [intevalId, setIntervalId] = React.useState(0)
  const [isSwitchLeftClicked, setIsSwitchLeftClicked] = React.useState(false)
  const activeIndexRef = React.useRef(activeIndex)

  React.useEffect(() => {
    activeIndexRef.current = activeIndex
  }, [activeIndex])

  React.useEffect(() => {
    const id = setInterval(() => {
      setActiveIndex(activeIndexRef.current === images.length - 1 ? 0 : activeIndexRef.current + 1)
    }, duration)

    setIntervalId(id)

    return () => clearInterval(intevalId)
  }, [])

  function slideSwitchButtonClicked(isLeft: boolean) {
    const nextIndex = isLeft
      ? activeIndexRef.current === 0
        ? images.length - 1
        : activeIndexRef.current - 1
      : activeIndexRef.current === images.length - 1
      ? 0
      : activeIndexRef.current + 1
    setActiveIndex(nextIndex)

    clearInterval(intevalId)

    const id = setInterval(() => {
      setActiveIndex(activeIndexRef.current === images.length - 1 ? 0 : activeIndexRef.current + 1)
      setIsSwitchLeftClicked(false)
    }, duration)

    setIntervalId(id)
    setIsSwitchLeftClicked(isLeft)
  }

  function myescape(str: string) {
    return str.replace(/[^a-zA-Z0-9@*_+\-./]/g, function(m) {
      const code = m.charCodeAt(0)
      if (code <= 0xff) {
        return '%' + ('00' + code.toString(16)).slice(-2).toUpperCase()
      } else {
        return '%u' + ('0000' + code.toString(16)).slice(-4).toUpperCase()
      }
    })
  }

  // ファイル名をそのまま使用しているため、エスケープ処理を行う
  images = images.map(image => myescape(image))

  return (
    <Root>
      {images.map((image, i) => (
        <EachSlide
          key={`slide-image-${i}`}
          state={
            i === activeIndex
              ? SlideState.IN
              : i === (activeIndex === 0 ? images.length - 1 : activeIndex - 1)
              ? !isSwitchLeftClicked
                ? SlideState.OUT
                : SlideState.STANDBY_LEFT
              : !isSwitchLeftClicked
              ? SlideState.STANDBY
              : SlideState.OUT_LEFT
          }
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: isCover ? 'cover' : 'contain',
            backgroundRepeat: isCover ? '' : 'no-repeat',
          }}
        />
      ))}
      <Indicators>
        {images.map((_, i) => (
          <Indicator key={`indicator-${i}`} isActive={i === activeIndex} />
        ))}
      </Indicators>
      {isShowSwitchButton && images.length > 1 && (
        <>
          <SlideSwitch style={{ left: 0 }} onClick={() => slideSwitchButtonClicked(true)}>
            <LeftArrowImage size={54} />
          </SlideSwitch>
          <SlideSwitch style={{ right: 0 }} onClick={() => slideSwitchButtonClicked(false)}>
            <RightArrowImage size={54} />
          </SlideSwitch>
        </>
      )}
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 500px;

  @media ${devices.mobile} {
    min-height: auto;
  }

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(700 / 927 * 100%);

    @media ${devices.mobile} {
      padding-bottom: calc(600 / 300 * 100%);
    }
  }
`

const eachSlideStyle = {
  [SlideState.IN]: css`
    z-index: 0;
    transform: translateX(0);
  `,
  [SlideState.OUT]: css`
    z-index: 0;
    transform: translateX(-100%);
  `,
  [SlideState.STANDBY]: css`
    z-index: -1;
    transform: translateX(100%);
  `,
  [SlideState.OUT_LEFT]: css`
    z-index: 0;
    transform: translateX(100%);
  `,
  [SlideState.STANDBY_LEFT]: css`
    z-index: -1;
    transform: translateX(-100%);
  `,
}

const EachSlide = styled.div<EachSlideProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  transition: transform ${TRANSITION_DURATION}ms;
  ${props => eachSlideStyle[props.state]}
`

const Indicators = styled.ul`
  z-index: 1;
  position: absolute;
  bottom: 16px;
  left: 50%;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  transform: translateX(-50%);
`

const Indicator = styled.li<IndicatorProps>`
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 4px;
  opacity: ${props => (props.isActive ? '.8' : '.4')};

  & + & {
    margin-left: 8px;
  }
`

const SlideSwitch = styled.div`
  position: absolute;
  top: 40%;
  cursor: pointer;
  color: black;
  opacity: 0.4;
  margin: 12px;
  @media ${devices.mobile} {
    margin: 8px;
  }
`

const RightArrowImage = styled(RightArrow)`
  @media ${devices.mobile} {
    width: 32px;
    height: 32px;
  }
`

const LeftArrowImage = styled(LeftArrow)`
  @media ${devices.mobile} {
    width: 32px;
    height: 32px;
  }
`
