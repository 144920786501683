import * as React from 'react'
import styled from 'styled-components'
import { devices } from '../../styleConstants'

type Props = React.HTMLAttributes<HTMLUListElement>

export default function TwoColumnList({ children, ...props }: Props) {
  return <Root {...props}>{children}</Root>
}

type ItemProps = React.HTMLAttributes<HTMLLIElement>

export function TwoColumnListItem({ children, ...props }: ItemProps) {
  return <Item {...props}>{children}</Item>
}

const Root = styled.ul<Props>`
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`

const Item = styled.li<ItemProps>`
  margin: 0;
  padding: 0;
  width: calc(50% - 180px / 2);

  @media ${devices.mobile} {
    width: 100%;
  }

  /* 2 column margin */
  &:nth-child(even) {
    margin-left: 180px;

    @media ${devices.mobile} {
      margin-left: 0;
    }
  }

  &:nth-child(n + 3) {
    margin-top: 64px;

    @media ${devices.mobile} {
      margin-top: 0;
    }
  }

  &:nth-child(n + 2) {
    @media ${devices.mobile} {
      margin-top: 40px;
    }
  }
`
