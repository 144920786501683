import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors, devices, fontFamilies } from '../../styleConstants'

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  look: 'primary' | 'secondary' | 'secondaryDark'
}

export default function SectionHeading({ children, ...props }: Props) {
  return <Root {...props}>{children}</Root>
}

const base = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 36px;
  font-family: ${fontFamilies.serif};
  line-height: 1;
  letter-spacing: 0.1em;

  @media ${devices.mobile} {
    font-size: 24px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 60px;
    height: 1px;

    @media ${devices.mobile} {
      width: 30px;
    }
  }

  &::before {
    margin-right: 0.5em;
  }

  &::after {
    margin-left: 0.5em;
  }
`

const look = {
  primary: css`
    color: ${colors.primary};

    &::before,
    &::after {
      background-color: ${colors.primary};
    }
  `,
  secondary: css`
    color: ${colors.secondary};

    &::before,
    &::after {
      background-color: ${colors.secondary};
    }
  `,
  secondaryDark: css`
    color: ${colors.secondaryDark};

    &::before,
    &::after {
      background-color: ${colors.secondaryDark};
    }
  `,
}

const Root = styled.h1<Props>`
  ${base}
  ${props => look[props.look]}
`
