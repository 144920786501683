import { Salon, fromJSON as salonFromJSON } from './salon'
import { request } from '../utils/request'
import { apiURL } from '../utils/url'
import { apiRoutes } from '../routes'
import { NextPageContext } from 'next'
import { Me, fetchMe } from './me'
import { Menu } from './menu'

export interface HairStylist {
  readonly id: number
  readonly email: string
  readonly name: string
  readonly name_ja: string
  readonly name_en: string
  readonly name_sort: string
  readonly hiragana: string
  readonly katakana: string
  readonly birthday: string
  readonly introduction: string
  readonly good_form: string
  readonly good_skill: string
  readonly job_title: string
  readonly hobby: string
  readonly comment: string
  readonly image: string
  readonly history: number
  readonly stars: StylistStar[]
  readonly instagram_account: string
  readonly enrollmentHistory: {
    readonly salonName: string
    readonly startYear: number
    readonly endYear: number
  }
  readonly salons: Salon[]
  readonly styleImages: StyleImage[]
  readonly memberId: string
  readonly role: string
  readonly showFlag: boolean
  readonly reservedSpotPolicy: ReservedSpotPolicy | null
  readonly birthdayDisplay: boolean
  readonly historyDisplay: boolean
  readonly specialSpotFlag: boolean
  readonly menus: Menu[]
}

export interface SearchItems {
  stylistName?: string
  areaId?: string
  starOption?: string
  freeWord?: string
  admin?: boolean
}

export interface StylistStar {
  readonly id: number
  readonly count: number
  readonly year: string
}

export interface ReservedSpotPolicy {
  readonly id: number
  readonly spots: number
}

export enum UpdatableParam {
  Name_JA = 'name_ja',
  Name_EN = 'name_en',
  Hiragana = 'hiragana',
  Katakana = 'katakana',
  Birthday = 'birthday',
  BirthdayDisplay = 'birthday_display',
  History = 'history',
  HistoryDisplay = 'history_display',
  Introduction_JA = 'introduction_ja',
  Introduction_EN = 'introduction_en',
  Comment_JA = 'comment_ja',
  Comment_EN = 'comment_en',
  Hobby_JA = 'hobby_ja',
  Hobby_EN = 'hobby_en',
  JobTitle_JA = 'job_title_ja',
  JobTitle_EN = 'job_title_en',
  GoodSkill_JA = 'good_skill_ja',
  GoodSkill_EN = 'good_skill_en',
  GoodForm_JA = 'good_form_ja',
  GoodForm_EN = 'good_form_en',
  InstagramAccount = 'instagram_account',
  ShowFlag = 'show_flag',
  SpecialSpotFlag = 'special_spot_flag',
}

export interface StyleImage {
  id: string
  image: string
  comment: string
}

export function fromJSON(json: any): HairStylist {
  return {
    id: json.id,
    email: json.email,
    name: json.name,
    name_ja: 'name_ja' in json ? json.name_ja : '',
    name_en: 'name_en' in json ? json.name_en : '',
    name_sort: 'name_en' in json ? json.name_sort : '',
    hiragana: 'hiragana' in json ? json.hiragana : '',
    katakana: 'katakana' in json ? json.katakana : '',
    birthday: 'birthday' in json ? json.birthday : '',
    birthdayDisplay: 'birthday_display' in json ? json.birthday_display : true,
    introduction: json.introduction,
    good_form: json.good_form,
    good_skill: json.good_skill,
    job_title: json.job_title,
    hobby: json.hobby,
    comment: json.comment,
    image: json.image,
    stars: 'stars' in json ? stylistStarFromJSONList(json) : new Array(0),
    instagram_account: json.instagram_account,
    history: json.history,
    historyDisplay: 'history_display' in json ? json.history_display : true,
    enrollmentHistory: 'enrollment_history' in json ? json.enrollment_history : { salon: '', startYear: 0, endYear: 0 },
    salons: 'salons' in json ? json.salons.map((salon: any) => salonFromJSON(salon)) : new Array(0),
    styleImages: 'style_images' in json ? fromJSONStyleImageList(json.style_images) : new Array(0),
    memberId: 'member_id' in json ? json.member_id : '',
    role: 'role' in json ? json.role : '',
    showFlag: 'show_flag' in json ? json.show_flag : false,
    reservedSpotPolicy: 'policy' in json ? reservedSpotPolicyFromJSON(json.policy) : null,
    specialSpotFlag: 'special_spot_flag' in json ? json.special_spot_flag : false,
    menus: 'menus' in json ? json.menus : new Array(0),
  }
}

export function fromJSONList(json_list: any): HairStylist[] {
  var result: HairStylist[] = []
  json_list.hair_stylists.forEach((json: any) => {
    result.push(fromJSON(json))
  })

  return result
}

export async function fetchHairStylist(id: string, req?: NextPageContext['req']): Promise<HairStylist | null> {
  const response = await request(apiURL(apiRoutes.stylistsId, id), {}, req)
  if (!response.ok) {
    return null
  }
  const json = await response.json()
  return fromJSON(json)
}

export async function fetchPickUpHairStylists(req?: NextPageContext['req']): Promise<HairStylist[] | null> {
  const reqURL = apiURL(apiRoutes.stylists)
  reqURL.searchParams.append('recommend', 'true')

  const response = await request(reqURL, {}, req)
  return createHairStylists(response)
}

export async function fetchTopSearchHairStylists(req?: NextPageContext['req']): Promise<HairStylist[] | null> {
  const reqURL = apiURL(apiRoutes.stylists)
  reqURL.searchParams.append('pick_up', 'true')

  const response = await request(reqURL, {}, req)
  return createHairStylists(response)
}

export async function fetchAllHairStylists(req?: NextPageContext['req']): Promise<HairStylist[] | null> {
  const reqURL = apiURL(apiRoutes.stylists)
  reqURL.searchParams.append('all', 'true')

  const response = await request(reqURL, {}, req)
  return createHairStylists(response)
}

export async function fetchSearchHairStylists(
  searchItems: SearchItems,
  req?: NextPageContext['req'],
): Promise<HairStylist[] | null> {
  const reqURL = apiURL(apiRoutes.stylists)
  if (searchItems.stylistName != null) {
    reqURL.searchParams.append('name', searchItems.stylistName)
  }

  if (searchItems.areaId != null) {
    reqURL.searchParams.append('area_id', searchItems.areaId)
  }

  if (searchItems.starOption != null) {
    reqURL.searchParams.append('has_star', searchItems.starOption)
  }

  if (searchItems.freeWord != null) {
    reqURL.searchParams.append('free_word', searchItems.freeWord)
  }

  if (searchItems.admin) {
    reqURL.searchParams.append('admin', 'true')
  }

  const response = await request(reqURL, {}, req)
  return createHairStylists(response)
}

export async function createHairStylist(userId: number): Promise<string | null> {
  const response = await request(apiURL(apiRoutes.stylists), {
    method: 'POST',
    body: JSON.stringify({ id: userId }),
  })

  if (!response.ok) {
    return null
  }

  /** Return created stylist data */
  const json = await response.json()
  return 'stylist_id' in json ? json.stylist_id : null
}

export async function updateStylist(id: string, updateParam: UpdatableParam, value: any) {
  let param: { [key: string]: any } = {}
  param[updateParam] = value

  const response = await request(apiURL(apiRoutes.stylistsId, id), {
    method: 'PATCH',
    body: JSON.stringify(param),
  })

  if (!response.ok) {
    throw new Error('Failed to update' + updateParam)
  }

  const stylist = await fetchHairStylist(id)

  if (!stylist) {
    throw new Error('Failed to fetch stylist')
  }
  return stylist
}

async function createHairStylists(response: Response) {
  if (!response.ok) {
    return null
  }

  const json_list = await response.json()
  return fromJSONList(json_list)
}

export async function createStylistStar(stylistId: string, count: string, year: string): Promise<HairStylist | null> {
  const response = await request(apiURL(apiRoutes.stylistStar), {
    method: 'POST',
    body: JSON.stringify({
      stylist_id: stylistId,
      count: count,
      year: year,
    }),
  })

  if (!response.ok) {
    return null
  }

  const stylist = await fetchHairStylist(stylistId)

  if (!stylist) {
    return null
  }
  return stylist
}

export async function deleteStylistStar(stylistId: string, starId: string): Promise<HairStylist | null> {
  const response = await request(apiURL(apiRoutes.stylistStar), {
    method: 'DELETE',
    body: JSON.stringify({
      star_id: starId,
    }),
  })

  if (!response.ok) {
    return null
  }

  const stylist = await fetchHairStylist(stylistId)

  if (!stylist) {
    return null
  }
  return stylist
}

export async function updateReserveSpotPolicy(stylistId: string, spots: number) {
  const response = await request(apiURL(apiRoutes.stylistReservedSpotsPolicies), {
    method: 'POST',
    body: JSON.stringify({ stylist_id: stylistId, spots: spots }),
  })

  if (!response.ok) {
    throw new Error('failed to update reserve spot policy')
  }

  const stylist = await fetchHairStylist(stylistId)

  if (!stylist) {
    throw new Error('Failed to fetch stylist')
  }
  return stylist
}

export async function updateStylistEnrollmentHistory(
  id: number,
  enrollhistory: HairStylist['enrollmentHistory'],
): Promise<Me> {
  const response = await request(apiURL(apiRoutes.stylists, String(id)), {
    method: 'PATCH',
    body: JSON.stringify({ enrollhistory: enrollhistory }),
  })
  if (!response.ok) {
    throw new Error('Failed to update enroll history')
  }

  const updated_me = await fetchMe()
  if (!updated_me) {
    throw new Error('Failed to fetch stylist')
  }

  return updated_me
}

export async function createStylistImage(stylistId: string, image: Blob): Promise<HairStylist> {
  const formData = new FormData()
  formData.append('stylist_id', stylistId)
  formData.append('image', image)

  const response = await request(apiURL(apiRoutes.stylistImage), {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    throw new Error('Failed to create stylist image')
  }

  const stylist = await fetchHairStylist(stylistId)

  if (!stylist) {
    throw new Error('Failed to fetch stylist')
  }
  return stylist
}

export async function createStyleImage(stylistId: string, image: Blob): Promise<HairStylist> {
  const formData = new FormData()
  formData.append('stylist_id', stylistId)
  formData.append('image', image)

  const response = await request(apiURL(apiRoutes.styleImage), {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    throw new Error('Failed to create stylist image')
  }

  const stylist = await fetchHairStylist(stylistId)

  if (!stylist) {
    throw new Error('Failed to fetch stylist')
  }
  return stylist
}

export async function deleteStyleImage(stylistId: string, imageId: string): Promise<HairStylist> {
  const formData = new FormData()
  formData.append('image_id', imageId)

  const response = await request(apiURL(apiRoutes.styleImage), {
    method: 'DELETE',
    body: formData,
  })

  if (!response.ok) {
    throw new Error('Failed to create stylist image')
  }

  const stylist = await fetchHairStylist(stylistId)

  if (!stylist) {
    throw new Error('Failed to fetch stylist')
  }
  return stylist
}

function fromJSONStyleImageList(json_style_image_list: any) {
  var styleImageList: StyleImage[] = []
  json_style_image_list.forEach((json: any) => {
    styleImageList.push({
      id: json.id,
      image: json.image,
      comment: json.comment,
    })
  })
  return styleImageList
}

function stylistStarFromJSONList(json_star_list: any): StylistStar[] {
  var result: StylistStar[] = []
  json_star_list.stars.forEach((json: any) => {
    result.push(stylistStarFromJSON(json))
  })

  return result
}

function stylistStarFromJSON(json: any): StylistStar {
  return {
    id: json.id,
    count: json.count ? json.count : 0,
    year: json.year,
  }
}

function reservedSpotPolicyFromJSON(json: any): ReservedSpotPolicy {
  return {
    id: json.id,
    spots: json.spots,
  }
}
