import * as React from 'react'
import { I18nCatalogue } from '../../i18n/common/types'
import { routeFactories } from '../../routes'
import { HairStylist } from '../../types/hairStylist'
import { StylistInfo } from './StylistInfo'
import FourColumnList, { FourColumnListItem, FourColumnListProps } from './FourColumnList'
import { getPreferredLanguage } from '../../i18n'

interface Props extends FourColumnListProps {
  readonly stylists: HairStylist[]
  readonly catalogue: I18nCatalogue
}

export default function StylistList({ stylists, catalogue, ...props }: Props) {
  return (
    <FourColumnList {...props}>
      {stylists.map(stylist => (
        <FourColumnListItem data-test="introduction-container" key={stylist.id}>
          <StylistInfo
            src={stylist.image}
            route={routeFactories.stylists.getRoute(stylist.id)}
            name={stylist.name}
            name_en={getPreferredLanguage() != 'en' ? stylist.name_en : ''}
            detail={catalogue.detail}
            buttonColor="secondary"
          />
        </FourColumnListItem>
      ))}
    </FourColumnList>
  )
}
