import { NextPageContext } from 'next'
import { UAParser } from 'ua-parser-js'

export enum UserAgentType {
  Desktop,
  Mobile,
}

export function getUserAgentType(req?: NextPageContext['req']): UserAgentType {
  const uaParserResult = new UAParser(getUserAgent(req)).getResult()

  switch (true) {
    case uaParserResult.device.type === 'mobile':
    case uaParserResult.device.type === 'tablet':
    case uaParserResult.os.name === 'Android':
    case uaParserResult.os.name === 'iOS':
      return UserAgentType.Mobile
    default:
      return UserAgentType.Desktop
  }
}

function getUserAgent(req?: NextPageContext['req']): string | undefined {
  if (typeof navigator === 'object') {
    return navigator.userAgent
  }
  if (req) {
    return req.headers['user-agent']
  }
  return undefined
}
