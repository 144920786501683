import { request } from '../utils/request'
import { apiURL } from '../utils/url'
import { apiRoutes } from '../routes'
import { NextPageContext } from 'next'
import { HairStylist, fromJSONList as stylistFromJSONList } from './hairStylist'

export interface Salon {
  readonly id: number
  readonly name: string
  readonly name_en: string
  readonly postCode: string
  readonly address: string
  readonly areaId: string
  readonly area: string
  readonly introduction: string
  readonly topImage: string
  readonly phoneNumber: string
  readonly salesCopy: string
  readonly logoImage: string
  readonly introductionImages: Array<{
    image: string
  }>
  readonly stylists: HairStylist[]
  readonly stylistsCount: number
  readonly stars: SalonStar[]
  readonly showFlag: boolean
  readonly url: string
  readonly openingTime: string
  readonly closingTime: string
  readonly paymentMethod: string
  readonly nearStation: string
}

export interface SalonStar {
  readonly id: number
  readonly colorCount: number
  readonly permCount: number
  readonly treatmentAndSpaCount: number
  readonly year: string
}

export enum UpdatableParam {
  Name_JA = 'name_ja',
  Name_EN = 'name_en',
  Introduction_JA = 'introduction_ja',
  Introduction_EN = 'introduction_en',
  Address_JA = 'address_ja',
  Address_EN = 'address_en',
  SalesCopy_JA = 'sales_copy_ja',
  SalesCopy_EN = 'sales_copy_en',
  PostCode = 'postcode',
  PhoneNumber = 'phone_number',
  AreaId = 'area_id',
  ShowFlag = 'show_flag',
  Url = 'url',
  OpeningTime_JA = 'open_hours_ja',
  OpeningTime_EN = 'open_hours_en',
  ClosingTime_JA = 'regular_holiday_ja',
  ClosingTime_EN = 'regular_holiday_en',
  PaymentMethod_JA = 'payment_ja',
  PaymentMethod_EN = 'payment_en',
  NearStation_JA = 'near_station_ja',
  NearStation_EN = 'near_station_en',
}

export enum Images {
  Logo,
  Top,
  Introduction,
}

export function fromJSON(json: any): Salon {
  return {
    id: json.id,
    name: json.name,
    name_en: 'name_en' in json ? json.name_en : '',
    postCode: json.postcode,
    address: json.address,
    areaId: json.area_id,
    area: json.area,
    introduction: json.introduction,
    topImage: json.top_image,
    phoneNumber: json.phone_number,
    salesCopy: json.sales_copy,
    logoImage: json.logo_image,
    introductionImages: json.introduction_image,
    stylists: 'hair_stylists' in json ? stylistFromJSONList(json) : new Array(0),
    stylistsCount: 'stylists_count' in json ? json.stylists_count : 0,
    stars: 'stars' in json ? SalonStarFromJSONList(json) : new Array(0),
    showFlag: json.show_flag,
    url: 'url' in json ? json.url : '',
    openingTime: 'open_hours' in json ? json.open_hours : '',
    closingTime: 'regular_holiday' in json ? json.regular_holiday : '',
    paymentMethod: 'payment' in json ? json.payment : '',
    nearStation: 'near_station' in json ? json.near_station : '',
  }
}

export function fromJSONList(json_list: any): Salon[] {
  var result: Salon[] = []
  json_list.salons.forEach((json: any) => {
    result.push(fromJSON(json))
  })

  return result
}

export async function fetchSalon(id: string, req?: NextPageContext['req']): Promise<Salon | null> {
  const response = await request(apiURL(apiRoutes.salonsId, id), {}, req)
  if (!response.ok) {
    return null
  }

  const json = await response.json()
  return fromJSON(json)
}

export async function fetchPickUpSalons(req?: NextPageContext['req']): Promise<Salon[] | null> {
  const reqURL = apiURL(apiRoutes.salons)
  reqURL.searchParams.append('recommend', 'true')

  const response = await request(reqURL, {}, req)
  if (!response.ok) {
    return null
  }

  const json_list = await response.json()
  return fromJSONList(json_list)
}

export async function fetchSalons(req?: NextPageContext['req']): Promise<Salon[] | null> {
  const reqURL = apiURL(apiRoutes.salons)

  const response = await request(reqURL, {}, req)
  if (!response.ok) {
    return null
  }

  const json_list = await response.json()
  return fromJSONList(json_list)
}

export async function createSalon(name: string): Promise<string | null> {
  const response = await request(apiURL(apiRoutes.salons), {
    method: 'POST',
    body: JSON.stringify({ name_ja: name, name_en: name }),
  })

  if (!response.ok) {
    return null
  }

  const json = await response.json()
  return json.salon_id
}

export async function updateSalon(id: string, updateParam: UpdatableParam, value: any) {
  let param: { [key: string]: any } = {}
  param[updateParam] = value

  const response = await request(apiURL(apiRoutes.salonsId, id), {
    method: 'PATCH',
    body: JSON.stringify(param),
  })

  if (!response.ok) {
    throw new Error('Failed to update salon:' + updateParam)
  }

  const salon = await fetchSalon(id)

  if (!salon) {
    throw new Error('Failed to fetch salon')
  }
  return salon
}

export async function createSalonStar(
  salonId: string,
  colorCount: string,
  permCount: string,
  treatmentAndSpaCount: string,
  year: string,
): Promise<Salon | null> {
  const response = await request(apiURL(apiRoutes.salonStar), {
    method: 'POST',
    body: JSON.stringify({
      salon_id: salonId,
      color_count: colorCount,
      perm_count: permCount,
      treatment_and_spa_count: treatmentAndSpaCount,
      year: year,
    }),
  })

  if (!response.ok) {
    return null
  }

  const salon = await fetchSalon(salonId)

  if (!salon) {
    return null
  }
  return salon
}

export async function deleteSalonStar(salonId: string, starId: string): Promise<Salon | null> {
  const response = await request(apiURL(apiRoutes.salonStar), {
    method: 'DELETE',
    body: JSON.stringify({
      star_id: starId,
    }),
  })

  if (!response.ok) {
    return null
  }

  const salon = await fetchSalon(salonId)

  if (!salon) {
    return null
  }
  return salon
}

export async function createSalonImage(salonId: string, image: File, targetImage: Images): Promise<Salon | null> {
  let reqURL = getImageAPIPath(targetImage)
  if (!reqURL) {
    return null
  }

  const formData = new FormData()
  formData.append('salon_id', salonId)
  formData.append('image', image)

  const response = await request(apiURL(reqURL), {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    return null
  }

  const salon = await fetchSalon(salonId)

  if (!salon) {
    return null
  }
  return salon
}

function SalonStarFromJSONList(json_star_list: any): SalonStar[] {
  var result: SalonStar[] = []
  json_star_list.stars.forEach((json: any) => {
    result.push(SalonStarFromJSON(json))
  })

  return result
}

function SalonStarFromJSON(json: any): SalonStar {
  return {
    id: json.id,
    colorCount: json.color_count ? json.color_count : 0,
    permCount: json.perm_count ? json.perm_count : 0,
    treatmentAndSpaCount: json.treatment_and_spacount ? json.treatment_and_spacount : 0,
    year: json.year,
  }
}

function getImageAPIPath(targetImage: Images) {
  switch (targetImage) {
    case Images.Logo:
      return apiRoutes.salonLogoImage

    case Images.Top:
      return apiRoutes.salonTopImage

    default:
      return null
  }
}

export async function createSalonMembership(salonId: string, userId: string): Promise<boolean> {
  const response = await request(apiURL(apiRoutes.salonMemberships), {
    method: 'POST',
    body: JSON.stringify({ salon_id: salonId, user_id: userId }),
  })

  return response.ok
}

export async function deleteSalonMembership(salonMemberId: string): Promise<boolean> {
  const response = await request(apiURL(apiRoutes.salonMembershipsId, salonMemberId), {
    method: 'DELETE',
  })

  return response.ok
}

export async function updateSalonMembershipRole(salonId: string, stylistId: string, role: string): Promise<boolean> {
  const response = await request(apiURL(apiRoutes.salonMembershipRole), {
    method: 'PATCH',
    body: JSON.stringify({ salon_id: salonId, stylist_id: stylistId, role: role }),
  })

  return response.ok
}
