import * as React from 'react'
import { Draft } from 'immer'
import { useImmer } from 'use-immer'
import { UserAgentType } from './detectUserAgent'
import { Me } from './types/me'
import { Props as SnackbarProps } from './components/common/Snackbar'

export interface GlobalContextType {
  readonly detectedUserAgent: UserAgentType
  readonly me?: Me
  readonly notifications: AppNotification[]
  readonly updateContext: UpdateContext
}

type ModifiableState = Omit<GlobalContextType, 'actions'>
type UpdateContext = (f: (draft: Draft<ModifiableState>) => void | ModifiableState) => void
export type AppNotification = Pick<SnackbarProps, 'text' | 'action'> & { readonly id?: string }

const initialState: GlobalContextType = {
  detectedUserAgent: UserAgentType.Mobile,
  notifications: [],
  updateContext: () => undefined,
}

export const GlobalContext = React.createContext(initialState)

interface Props {
  readonly detectedUserAgent: UserAgentType
  readonly me?: Me
  readonly children: React.ReactNode
}

export function GlobalContextWrapper({ detectedUserAgent, me, children }: Props) {
  const [context, updateContext] = useImmer<ModifiableState>({
    ...initialState,
    detectedUserAgent,
    me,
  })

  return <GlobalContext.Provider value={{ ...context, updateContext }}>{children}</GlobalContext.Provider>
}
