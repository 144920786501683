import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors, devices } from '../../styleConstants'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  readonly bgColor?: 'white' | 'gray' | 'secondary'
}

export default function Section({ children, ...props }: Props) {
  return <Root {...props}>{children}</Root>
}

const base = css`
  padding: 80px 0 100px;

  @media ${devices.mobile} {
    padding: 60px 0;
  }
`

const bgColor = {
  white: css`
    background-color: ${colors.white};
  `,
  gray: css`
    background-color: ${colors.gray50};
  `,
  secondary: css`
    background-color: ${colors.secondaryExtraLight};
  `,
}

const Root = styled.div<Props>`
  ${base}
  ${props => bgColor[props.bgColor || 'white']}
`
