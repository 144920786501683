import * as React from 'react'
import styled, { css } from 'styled-components'
import cc from 'classcat'
import { RawLink, Props as LinkProps } from './Link'
import { colors } from '../../styleConstants'

interface Props {
  readonly size?: 'tiny' | 'small' | 'large'
  readonly src: string
  readonly alt: string
  readonly bordered?: boolean
  readonly round?: boolean
  readonly route?: LinkProps['route']
  readonly href?: LinkProps['href']
  readonly 'data-test'?: string
  readonly isHoverScale?: boolean
}

export function Avatar({ size, src, alt, bordered, round, route, href, 'data-test': dataTest, isHoverScale }: Props) {
  if (route || href) {
    return (
      <LinkContainer route={route} href={href} className={cc([size])} data-test={dataTest}>
        {isHoverScale && <ScalableImg src={src} alt={alt} className={cc([size])} />}
        {!isHoverScale && <Img src={src} alt={alt} className={cc([size])} />}
      </LinkContainer>
    )
  }
  return (
    <Container className={cc([size])} data-test={dataTest}>
      <Img
        src={src != '' ? src : '/static/account-circle.png'}
        alt={alt}
        className={cc([size, { bordered, round }])}
        data-test={dataTest}
      />
    </Container>
  )
}

const avatarSize = css`
  &.tiny {
    width: 32px;
  }
  &.small {
    width: 12rem;
  }
  &.large {
    width: 20rem;
  }
`

const container = css`
  position: relative;
  display: inline-block;
  width: 100%;
  border-style: none;
  border-width: 1px;
  border-color: ${colors.secondaryDark};
  ${avatarSize}

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

const Container = styled.span`
  ${container}
`

const LinkContainer = styled(RawLink)`
  ${container}
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  margin: 0;
  width: 100%;
  height: 100%;

  &.bordered {
    border-style: solid;
  }

  &.round {
    border-radius: 50%;
  }
`

const ScalableImg = styled(Img)`
  width: 100%;
  transition-duration: 0.5s;

  :hover {
    transform: scale(1.1);
    transition-duration: 0.5s;
  }
`
