import * as React from 'react'
import styled from 'styled-components'

const FloatingBanner = () => {
  return (
    <FloatingBannerWrapper
      onClick={() => {
        window.location.href = "concierge";
      }}>
      Your Booking Concierge Service
      <div>※Concierge Service only in English now.</div>
    </FloatingBannerWrapper>
  );
};

const FloatingBannerWrapper = styled.div`
  position: fixed;
  bottom: 70px;
  right: 10px;
  border-radius: 3px;
  background-color: rgba(214, 80, 79, 0.8);
  padding: 10px 10px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.0rem;
  cursor: pointer

  div:last-child {
    font-size: 0.7rem;
  }
`;

export default FloatingBanner;