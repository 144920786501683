import { User, fromJSON as userFromJSON } from './user'
import { HairStylist, fromJSON as hairStylistFromJSON } from './hairStylist'
import { Administrator, fromJSON as administratorFromJSON } from './administrator'
import { request } from '../utils/request'
import { apiURL } from '../utils/url'
import { apiRoutes } from '../routes'
import { NextPageContext } from 'next'
import { Language } from '../i18n/types'

export interface Me {
  readonly user: User
  readonly hairStylist?: HairStylist
  readonly administrator?: Administrator
  readonly salonAuth: boolean
}

export function fromJSON(json: any): Me {
  return {
    user: userFromJSON(json.user),
    hairStylist: json.hair_stylist ? hairStylistFromJSON(json.hair_stylist) : undefined,
    administrator: json.administrator ? administratorFromJSON(json.administrator) : undefined,
    salonAuth: 'salon_auth' in json ? json.salon_auth : false,
  }
}

export async function fetchMe(req?: NextPageContext['req']): Promise<Me | undefined> {
  const response = await request(apiURL(apiRoutes.me.root), {}, req)
  if (!response.ok) {
    return
  }
  const json = await response.json()
  return fromJSON(json)
}

export async function updateEmail(email: string): Promise<Me> {
  const response = await request(apiURL(apiRoutes.me.email), { method: 'PATCH', body: JSON.stringify({ email }) })
  if (!response.ok) {
    throw new Error('Failed to update email')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function updateName(name: string): Promise<Me> {
  const response = await request(apiURL(apiRoutes.me.name), { method: 'PATCH', body: JSON.stringify({ name }) })
  if (!response.ok) {
    throw new Error('Failed to update name')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function updatePreferredLanguage(language: Language): Promise<Me> {
  const response = await request(apiURL(apiRoutes.me.preferredLanguage), {
    method: 'PATCH',
    body: JSON.stringify({ preferred_language: language }),
  })
  if (!response.ok) {
    throw new Error('Failed to update preferredLanguage')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function updateImage(image: Blob): Promise<Me> {
  const formData = new FormData()
  formData.append('image', image)
  const response = await request(apiURL(apiRoutes.me.image), {
    method: 'PATCH',
    body: formData,
  })
  if (!response.ok) {
    throw new Error('Failed to update image')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function deleteImage(): Promise<Me> {
  const response = await request(apiURL(apiRoutes.me.image), { method: 'DELETE' })
  if (!response.ok) {
    throw new Error('Failed to delete image')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function configureBirthday(birthday: string): Promise<Me> {
  const response = await request(apiURL(apiRoutes.me.birthday), {
    method: 'POST',
    body: JSON.stringify({ birthday }),
  })
  if (!response.ok) {
    throw new Error('Failed to update birthday')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function updatePhoneNumber(phoneNumber: string | null): Promise<Me> {
  const response = await request(apiURL(apiRoutes.me.phoneNumber), {
    method: phoneNumber ? 'PATCH' : 'DELETE',
    body: JSON.stringify({ phone_number: phoneNumber }),
  })
  if (!response.ok) {
    throw new Error('Failed to update phoneNumber')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function updateAddress(
  zipCode: string,
  prefecture: string,
  city: string,
  address: string,
  addressDetail: string | null,
): Promise<Me> {
  const response = await request(apiURL(apiRoutes.me.address), {
    method: 'PATCH',
    body: JSON.stringify({
      zip_code: zipCode,
      prefecture: prefecture,
      city: city,
      address: address,
      address_detail: addressDetail,
    }),
  })
  const me = await fetchMe()
  if (!response.ok) {
    throw new Error('Failed to udpate address')
  }
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function updatePassword(password: string, oldPassword?: string): Promise<Me> {
  const response = await request(apiURL(apiRoutes.me.password), {
    method: 'PATCH',
    body: JSON.stringify({ password, old_password: oldPassword }),
  })
  if (!response.ok) {
    throw new Error('Failed to update password')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function addStylistToFavorite(stylistId: number) {
  const response = await request(apiURL(apiRoutes.me.favorite), {
    method: 'POST',
    body: JSON.stringify({ stylist_id: stylistId }),
  })
  if (!response.ok) {
    throw new Error('Failed to add a stylist to favorite')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function removeFavorite(id: number) {
  const response = await request(apiURL(apiRoutes.me.favoriteId, String(id)), {
    method: 'DELETE',
  })
  if (!response.ok) {
    throw new Error('Failed to remove favorite')
  }
  const me = await fetchMe()
  if (!me) {
    throw new Error('Failed to fetch me')
  }
  return me
}

export async function deleteAccount(): Promise<void> {
  const response = await request(apiURL(apiRoutes.me.root), {
    method: 'DELETE',
  })
  if (!response.ok) {
    throw new Error('Failed to delete account')
  }
}

export const isHairStylist = (me: Me | undefined): boolean => {
  if (!me) {
    return false
  }
  return !!me.hairStylist
}

export const isAdministrator = (me: Me | undefined): boolean => {
  if (!me) {
    return false
  }
  return !!me.administrator
}
