import styled from 'styled-components'
import { SalonImg } from '../../components/root/SalonImg'
import { Props as LinkProps } from '../common/Link'

const Container = styled.div``
const Name = styled.div`
  margin-top: 10px;
  font-size: 18px;
  line-height: 27px;
`
const Detail = styled.div`
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
`

interface Props {
  readonly src: string
  readonly route?: LinkProps['route']
  readonly href?: LinkProps['href']
  readonly name: string
  readonly detail: string
}

export function SalonInfo({ src, route, href, name, detail }: Props) {
  return (
    <Container>
      <SalonImg src={src} href={href} alt="" route={route} />
      <Name>{name}</Name>
      {false && <Detail>{detail}</Detail>}
    </Container>
  )
}
