import * as React from 'react'
import styled from 'styled-components'
import { devices, sizes } from '../../styleConstants'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export default function Container({ children, ...props }: Props) {
  return <Root {...props}>{children}</Root>
}

const Root = styled.div`
  margin: 0 auto;
  padding: 0 ${sizes.sideMargin};
  width: calc(${sizes.contentWidth} + ${sizes.sideMargin} * 2);

  @media ${devices.mobile} {
    padding: 0 20px;
    width: 100%;
  }
`
