import * as React from 'react'
import styled from 'styled-components'
import { devices } from '../../styleConstants'

export type FourColumnListProps = React.HTMLAttributes<HTMLUListElement>

export default function FourColumnList({ children, ...props }: FourColumnListProps) {
  return <Root {...props}>{children}</Root>
}

export type FourColumnListItemProps = React.HTMLAttributes<HTMLLIElement>

export function FourColumnListItem({ children, ...props }: FourColumnListItemProps) {
  return <Item {...props}>{children}</Item>
}

const Root = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  margin: 0;
  padding: 0;
  width: calc(25% - 100px * 3 / 4);

  @media ${devices.mobile} {
    width: calc(50% - 20px / 2);
  }

  /* 2-4 column margin */
  &:nth-child(4n + 2),
  &:nth-child(4n + 3),
  &:nth-child(4n + 4) {
    margin-left: 100px;

    @media ${devices.mobile} {
      margin-left: 0;
    }
  }

  &:nth-child(n + 5) {
    margin-top: 66px;

    @media ${devices.mobile} {
      margin-top: 0;
    }
  }

  &:nth-child(even) {
    @media ${devices.mobile} {
      margin-left: 20px;
    }
  }

  &:nth-child(n + 3) {
    @media ${devices.mobile} {
      margin-top: 40px;
    }
  }
`
