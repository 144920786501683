import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { RawLink, Props as LinkProps } from '../common/Link'
import { colors } from '../../styleConstants'

interface Props {
  readonly src: string
  readonly alt: string
  readonly bordered?: boolean
  readonly route?: LinkProps['route']
  readonly href?: LinkProps['href']
}

export function SalonImg({ src, alt, bordered, route, href }: Props) {
  if (route || href) {
    return (
      <Container route={route} href={href} className={cc([bordered])}>
        <Img src={src} alt={alt} />
      </Container>
    )
  }
  return <Img src={src} alt={alt} />
}

const Container = styled(RawLink)`
  position: relative;
  display: inline-block;
  width: 100%;
  border-style: none;
  border-width: 1px;
  border-color: ${colors.secondaryDark};

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(300 / 460 * 100%);
  }

  &.bordered {
    border-style: solid;
  }
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
