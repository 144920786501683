import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Button } from './Button'
import { Avatar } from './Avatar'
import { Props as LinkProps } from './Link'

const Container = styled.div``

const DetailButton = styled(Button)`
  width: 100%;
  flex-direction: column;
  border-radius: 30px;
  padding: 0.8rem 0;

  &.secondary {
    color: ${colors.secondary};
    border-color: ${colors.secondary};
  }

  &.primary {
    color: ${colors.primary};
    border-color: ${colors.primary};
  }
`

const Name = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.secondaryDark};
  @media (max-width: 800px) {
    font-size: 14px;
  }
`

const NameEn = styled.span`
  color: gray;
  font-size: 14px;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`

interface Props {
  readonly src: string
  readonly route?: LinkProps['route']
  readonly href?: LinkProps['href']
  readonly buttonColor: 'secondary' | 'primary'
  readonly name: string
  readonly name_en: string
  readonly detail: string
}

export function StylistInfo({ src, route, href, buttonColor, name, name_en, detail }: Props) {
  return (
    <Container>
      <Avatar src={src} alt="" bordered route={route} href={href} isHoverScale={true} />
      <Name>
        {name}
        {name_en !== '' && (
          <>
            <br />
            <NameEn>{name_en}</NameEn>
          </>
        )}
      </Name>
      {false && (
        <DetailButton look="white" route={route} href={href} bordered className={buttonColor}>
          {detail}
        </DetailButton>
      )}
    </Container>
  )
}
