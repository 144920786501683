import { Language } from '../i18n/types'
import { request } from '../utils/request'
import { apiURL } from '../utils/url'
import { apiRoutes } from '../routes'
import { NextPageContext } from 'next'

export interface User {
  readonly id: number
  readonly email: string
  readonly updatingEmail: boolean
  readonly name: string
  readonly preferredLanguage: Language
  readonly image: {
    readonly '32px': string
    readonly '320px': string
  } | null
  readonly phoneNumber: string | null
  readonly zipCode: string | null
  readonly prefecture: string | null
  readonly city: string | null
  readonly address: string | null
  readonly addressDetail: string | null
  readonly birthday: string | null
  readonly hasPassword: boolean
  readonly favoriteHairStylists: {
    readonly id: number
    readonly hairStylistId: number
  }[]
  readonly temporary_user: boolean | null
  readonly hairStylistId: number | null
  readonly hasSalonAdmin: boolean | null
  readonly menusCount: number | null
  readonly adminId: number | null
}

export function fullAddress(user: User) {
  const { zipCode, prefecture, city, address, addressDetail } = user
  if (zipCode && prefecture && city && address) {
    const str = '〒' + zipCode + ' ' + prefecture + city + address
    if (addressDetail != null) {
      return str + addressDetail
    } else {
      return str
    }
  } else {
    return null
  }
}

export async function fetchSearchUser(email: string, req?: NextPageContext['req']) {
  const reqURL = apiURL(apiRoutes.searchUser)
  reqURL.searchParams.append('email', email)

  const response = await request(reqURL, {}, req)
  if (!response.ok) {
    return null
  }
  const json = await response.json()

  return fromJSON(json)
}

export async function fetchUsers(req?: NextPageContext['req']) {
  const reqURL = apiURL(apiRoutes.users)

  const response = await request(reqURL, {}, req)
  if (!response.ok) {
    return null
  }

  return createUsers(response)
}

export function fromJSON(json: any): User {
  return {
    id: json.id,
    email: json.email,
    name: json.name,
    updatingEmail: json.updating_email,
    preferredLanguage: json.preferred_language,
    image: json.image,
    phoneNumber: json.phone_number,
    zipCode: json.zip_code,
    prefecture: json.prefecture,
    city: json.city,
    address: json.address,
    addressDetail: json.address_detail,
    birthday: json.birthday,
    hasPassword: json.has_password,
    favoriteHairStylists: json.favorite_hair_stylists.map((favorite: any) => ({
      id: favorite.id,
      hairStylistId: favorite.hair_stylist_id,
    })),
    temporary_user: json.temporary_user,
    hairStylistId: json.hair_stylist_id,
    hasSalonAdmin: json.has_salon_admin,
    menusCount: json.menus_count,
    adminId: json.admin_id,
  }
}

export function fromJSONList(json_list: any): User[] {
  var result: User[] = []
  json_list.users.forEach((json: any) => {
    result.push(fromJSON(json))
  })

  return result
}

async function createUsers(response: Response) {
  if (!response.ok) {
    return null
  }

  const json_list = await response.json()
  return fromJSONList(json_list)
}

export function getFavoriteByStylistId(user: User, stylistId: number) {
  for (const favorite of user.favoriteHairStylists) {
    if (favorite.hairStylistId === stylistId) {
      return favorite
    }
  }
  return null
}

export async function sendNotice(mailForm: { title: string; text: string }, userIds: Array<number>) {
  const params = { title: mailForm.title, text: mailForm.text, user_ids: userIds }
  const response = await request(apiURL(apiRoutes.sendNoticeMailToUsers), {
    method: 'POST',
    body: JSON.stringify(params),
  })
  if (!response.ok) {
    throw new Error('Failed to send notice mail')
  }
}
