import * as React from 'react'
import styled from 'styled-components'
import { Button } from '../frontend-lib/components/common/Button'
import Container from '../frontend-lib/components/common/Container'
import Section from '../frontend-lib/components/common/Section'
import SectionHeading from '../frontend-lib/components/common/SectionHeading'
import Slider from '../frontend-lib/components/common/Slider/Slider'
import StylistList from '../frontend-lib/components/common/StylistList'
import TwoColumnList, { TwoColumnListItem } from '../frontend-lib/components/common/TwoColumnList'
import FloatingBanner from '../frontend-lib/components/common/FloatingBanner'
import { SalonInfo } from '../frontend-lib/components/root/SalonInfo'
import { UserAgentType } from '../frontend-lib/detectUserAgent'
import { GlobalContext } from '../frontend-lib/globalContext'
import { loadCatalogue } from '../frontend-lib/i18n'
import { I18nCatalogue } from '../frontend-lib/i18n/root/types'
import { routeFactories } from '../frontend-lib/routes'
import { colors, devices } from '../frontend-lib/styleConstants'
import { fetchPickUpHairStylists, fetchTopSearchHairStylists, HairStylist } from '../frontend-lib/types/hairStylist'
import { fetchPickUpSalons, Salon } from '../frontend-lib/types/salon'
import { KamismaPageContext } from './_app'

interface Props {
  readonly catalogue: I18nCatalogue
  readonly stylists: HairStylist[]
  readonly searchStylists: HairStylist[]
  readonly salons: Salon[]
}

const Hero = styled.div`
  position: relative;
`
const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`
const Title = styled.h1`
  margin: 0;
  font-size: 36px;
  font-weight: normal;
  line-height: 54px;
  text-align: center;
  color: ${colors.white};

  @media ${devices.mobile} {
    font-size: 24px;
  }
`
const SubTitle = styled.h2`
  margin: 30px 0 48px;
  font-size: 18px;
  font-weight: normal;
  line-height: 36px;
  text-align: center;
  color: ${colors.white};

  @media ${devices.mobile} {
    font-size: 14px;
    padding: 0px 8px;
  }
`

const SlideSource = styled.p`
  padding-right: 0.5rem;
  font-size: 9px;
  text-align: right;
`

const PrimarySubTitle = styled.div`
  margin: 23px 0 0;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.primary};
`
const BookContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 60px auto 0;
  max-width: calc(400px + 100px + 784px);

  @media ${devices.mobile} {
    flex-direction: column;
    max-width: auto;
  }
`
const BookImg = styled.div`
  margin: 0;
  text-align: center;

  img,
  p {
    max-width: 300px;
    margin: 0 96px;
  }

  p {
    font-size: 9px;
    text-align: right;
  }

  @media ${devices.mobile} {
    img,
    p {
      max-width: 100%;
      margin: auto;
    }
  }
`

const BookExp = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 30px;

  @media ${devices.mobile} {
    margin-top: 20px;
    padding-left: 0;
  }
`
const SecondaryDarkSubTitle = styled(PrimarySubTitle)`
  color: ${colors.secondaryDark};
`
const SalonList = styled(TwoColumnList)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;
`
const ScissorsImg = styled.img`
  width: 90px;
  vertical-align: bottom;
`

const SalonListItem = TwoColumnListItem

const SearchButton = styled(Button)`
  cursor: pointer;
  border: solid 1.2px white;
`

const SearchButtonTitle = styled.span`
  font-size: 1.2em;
`

const SearchStylistAnchor = styled.div`
  position: relative;
  top: 740px;
  @media ${devices.mobile} {
    top: 540px;
  }
`

const SalonGuideStyle = styled.span`
  @media ${devices.mobile} {
    font-size: 28px;
  }
`

const Index = ({ catalogue, stylists, searchStylists, salons }: Props) => {
  /*const context = React.useContext(GlobalContext)
  const { me } = context
  console.log(me)*/
  const [state] = React.useState<{ searchStylists: HairStylist[] }>({
    searchStylists: searchStylists,
  })

  const searchStylistsTitleRef = React.createRef<HTMLDivElement>()

  const scrollToSearchStylistsTitle = React.useCallback(() => {
    searchStylistsTitleRef!.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }, [searchStylistsTitleRef])

  const pickupSalons =
    salons != null && salons.length > 0
      ? salons.map(salon => {
          return (
            <SalonListItem key={salon.id}>
              <SalonInfo
                src={salon.topImage}
                route={routeFactories.salons.getRoute(salon.id)}
                name={salon.name}
                detail={salon.introduction}
              />
            </SalonListItem>
          )
        })
      : null
  const { detectedUserAgent } = React.useContext(GlobalContext)
  const topImages =
    detectedUserAgent === UserAgentType.Desktop
      ? ['/static/top1.jpg', '/static/top2.jpg', '/static/top3.jpg', '/static/top4.jpg']
      : ['/static/top1-mobile.jpg', '/static/top2-mobile.jpg', '/static/top3-mobile.jpg', '/static/top4-mobile.jpg']

  return (
    <>
      <Hero>
        <Slider images={topImages} isCover={true} />
        <HeroContent>
          <Title>{catalogue.title}</Title>
          <SubTitle>{catalogue.subTitle}</SubTitle>
          <SearchButton look="transparent" onClick={() => scrollToSearchStylistsTitle()}>
            <SearchButtonTitle>{catalogue.searchMessage}</SearchButtonTitle>
          </SearchButton>
        </HeroContent>
      </Hero>
      <SlideSource>{catalogue.slideSource}</SlideSource>
      <Section bgColor="gray">
        <Container>
          <SectionHeading look="secondaryDark">
            <SalonGuideStyle>{catalogue.bookTitle}</SalonGuideStyle>
          </SectionHeading>
          <BookContainer>
            <BookImg>
              <img src="/static/kamisma-book.jpg" />
              <p>{catalogue.bookSource}</p>
            </BookImg>
            <BookExp>
              {catalogue.bookExp}
              <ScissorsImg src="/static/scissors.png"></ScissorsImg>
            </BookExp>
          </BookContainer>
        </Container>
      </Section>
      {stylists != null && stylists.length > 0 && (
        <Section bgColor="secondary">
          <Container>
            <SectionHeading look="secondary">{catalogue.pickupStylistTitle}</SectionHeading>
            {false && <SecondaryDarkSubTitle>{catalogue.pickupStylistSubTitle}</SecondaryDarkSubTitle>}
            <StylistList stylists={stylists} catalogue={catalogue} style={{ marginTop: '60px' }} />
          </Container>
        </Section>
      )}
      <Section bgColor="gray">
        <Container>
          <SectionHeading look="primary">{catalogue.searchStylistTitle}</SectionHeading>
          <SecondaryDarkSubTitle>
            {catalogue.searchStylistSubTitle}
            <br />
            {catalogue.searchStylistSubTitle2}
          </SecondaryDarkSubTitle>
          <SearchStylistAnchor ref={searchStylistsTitleRef}></SearchStylistAnchor>
          <StylistList stylists={state.searchStylists} catalogue={catalogue} style={{ marginTop: '60px' }} />
          <Button
            look="primary"
            route={routeFactories.explore.getRoute()}
            bordered
            style={{ display: 'block', margin: '60px auto 0', width: '260px' }}>
            {catalogue.andMore}
          </Button>
        </Container>
      </Section>
      {pickupSalons != null && (
        <Section bgColor="white">
          <Container>
            <SectionHeading look="secondaryDark">{catalogue.pickupSalonTitle}</SectionHeading>
            <SecondaryDarkSubTitle>{catalogue.pickupSalonSubTitle}</SecondaryDarkSubTitle>
            <SalonList>{pickupSalons}</SalonList>
          </Container>
        </Section>
      )}
      <FloatingBanner/>
    </>
  )
}

Index.getInitialProps = async ({ req, me }: KamismaPageContext) => {
  const catalogue = await loadCatalogue<I18nCatalogue>('root', me, req)
  const stylists = await fetchPickUpHairStylists(req)
  const searchStylists = await fetchTopSearchHairStylists(req)
  const salons = await fetchPickUpSalons(req)

  return { catalogue, stylists, searchStylists, salons }
}

export default Index
